<template>
  <b-container fluid class="mb-5">
    <b-row>
      <b-col>
        <div class="d-flex justify-content-between mb-4">
          <h4>Detalhes</h4>
        </div>

        <b-card-text class="d-flex flex-column">
          <b-form @submit.prevent="updateCompany">
            <b-row>
              <b-col md="3">
                <form-input
                  v-model="detailCompanyFormated.zipCode"
                  :errors="errors.zipCode"
                  label="CEP"
                  icon="signpost-split"
                  required
                >
                </form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <form-input
                  v-model="detailCompanyFormated.cnpj"
                  :errors="errors.cnpj"
                  label="CPF/CNPJ"
                  icon="building"
                  required
                >
                </form-input>
              </b-col>

              <b-col md="3">
                <form-input
                  v-model="detailCompanyFormated.ie"
                  :errors="errors.ie"
                  label="Inscrição Estadual"
                  icon="file-medical"
                  required
                >
                </form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-input
                  v-model="detailCompanyFormated.oficialName"
                  :errors="errors.oficialName"
                  label="Nome Oficial"
                  icon="card-heading"
                  required
                >
                </form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="detailCompanyFormated.socialName"
                  :errors="errors.socialName"
                  label="Nome social"
                  icon="people"
                  required
                >
                </form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="detailCompanyFormated.email"
                  :errors="errors.email"
                  label="Email"
                  icon="at"
                  required
                >
                </form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-input
                  v-model="detailCompanyFormated.address"
                  :errors="errors.address"
                  label="Endereço"
                  icon="signpost-split"
                  required
                >
                </form-input>
              </b-col>

              <b-col md="2">
                <form-input
                  v-model="detailCompanyFormated.number"
                  :errors="errors.number"
                  label="Número"
                  icon="mailbox"
                  required
                >
                </form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-input
                  v-model="detailCompanyFormated.district"
                  :errors="errors.district"
                  label="Bairro"
                  icon="map"
                  required
                >
                </form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="detailCompanyFormated.city"
                  :errors="errors.city"
                  label="Cidade"
                  icon="pin-map"
                  required
                >
                </form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="detailCompanyFormated.state"
                  :errors="errors.state"
                  label="Estado"
                  icon="signpost-split"
                  required
                >
                </form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <form-input
                  v-model="detailCompanyFormated.references"
                  :errors="errors.references"
                  label="Referência"
                  icon="signpost-split"
                  required
                >
                </form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="detailCompanyFormated.complement"
                  :errors="errors.complement"
                  label="Complemento"
                  icon="card-text"
                  required
                >
                </form-input>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="3">
                <form-select
                  v-model="detailCompanyFormated.billMethod"
                  :errors="errors.billMethod"
                  :options="billMethodOptions"
                  icon="cash-coin"
                  label="Método de cobrança"
                  required
                >
                </form-select>
              </b-col>

              <b-col>
                <form-input
                  v-model="detailCompanyFormated.deadline"
                  :errors="errors.deadline"
                  icon="stopwatch"
                  label="Dias uteis para entrega"
                  required
                  type="number"
                >
                </form-input>
              </b-col>

              <b-col>
                <form-input
                  v-model="detailCompanyFormated.minimumValue"
                  :errors="errors.minimumValue"
                  icon="coin"
                  label="Valor minimo"
                  required
                  type="number"
                >
                </form-input>
              </b-col>
            </b-row>

            <div class="mt-3 d-flex justify-content-end">
              <b-button type="submit" variant="primary">SALVAR</b-button>
            </div>
          </b-form>
        </b-card-text>
      </b-col>
    </b-row>
  </b-container>
</template>
<script>
import { mapMutations } from "vuex";
import http from "../../plugins/http";
import FormInput from "../../components/form/FormInput.vue";
import FormSelect from "../../components/form/FormSelect.vue";
import { formatCpfCnpj } from "../../utils/formatCpfCnpj";
import { maskBr } from "js-brasil";

export default {
  components: { FormInput, FormSelect },
  data() {
    return {
      detailCompany: {
        active: null,
        address: "",
        billMethod: "",
        city: "",
        cnpj: "",
        complement: "",
        deadline: "",
        display: null,
        district: "",
        email: "",
        id: 0,
        ie: "",
        minimumValue: "",
        number: "",
        oficialName: "",
        references: "",
        socialName: "",
        state: "",
        zipCode: "",
      },
      billMethodOptions: [
        { id: "VALOR", text: "Valor" },
        { id: "PORCENTAGEM", text: "Porcentagem" },
      ],
      errors: {},
    };
  },

  computed: {
    detailCompanyFormated() {
      return {
        ...this.detailCompany,
        cnpj: formatCpfCnpj(this.detailCompany.cnpj),
        zipCode: maskBr.cep(this.detailCompany.zipCode),
      };
    },
  },

  methods: {
    ...mapMutations(["setToastedError", "setToastedSuccess", "setOverlay"]),

    async updateCompany() {
      try {
        this.errors = {};
        this.setOverlay(true);
        const { data } = await http.put(
          `/shipping/update/${this.detailCompany.id}`,
          {
            address: this.detailCompany.address,
            billMethod: this.detailCompany.billMethod,
            city: this.detailCompany.city,
            cnpj: this.detailCompany.cnpj,
            complement: this.detailCompany.complement,
            district: this.detailCompany.district,
            email: this.detailCompany.email,
            ie: this.detailCompany.ie,
            number: this.detailCompany.number,
            oficialName: this.detailCompany.oficialName,
            references: this.detailCompany.references,
            socialName: this.detailCompany.socialName,
            state: this.detailCompany.state,
            zipCode: this.detailCompany.zipCode,
            deadline: Number(this.detailCompanyFormated.deadline),
            minimumValue: Number(this.detailCompanyFormated.minimumValue),
          }
        );
        this.detailCompany = data;

        this.setToastedSuccess({
          message: "Dados da transportadora atualizados com sucesso",
        });
      } catch (error) {
        if (error.response.status === 406) {
          this.errors = error.response.data.error;
        } else {
          this.setToastedError({
            message: error.response.data.error,
          });
        }
      }
      this.setOverlay(false);
    },
  },
  async created() {
    this.setOverlay(true);
    const id = this.$route.params.id;
    const { data } = await http(`shipping/detail/${id}`);
    this.detailCompany = data;
    this.setOverlay(false);
  },
};
</script>
